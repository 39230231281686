.graphiql-container .title {
    display: none;
}

.graphiql-container .variable-editor-title {
    display: none;
}

.graphiql-container .queryWrap {
    flex: 350 1 0;
}