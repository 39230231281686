.graphiql-container .title {
    display: none;
}

.graphiql-container .variable-editor-title {
    display: none;
}

.graphiql-container .queryWrap {
    flex: 350 1;
}
*{box-sizing:border-box;margin:0;padding:0}html{height:100%;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}body{background-color:#f2f2f2;height:100%}a{text-decoration:none}ul{list-style:circle inside none}#root{height:100%}

